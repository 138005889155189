//
// Site section
//

.section {
  position: relative;
  overflow: hidden;
  padding: map_get($spacers, 19) 0;
  background: $body-bg;

  @include media-breakpoint-up(sm) {
    padding: map_get($spacers, 23) 0;
  }

  @include media-breakpoint-up(lg) {
    padding: map_get($spacers, 25) 0;
  }
}

.section-background-color, .section-background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.section-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-background-color {
  z-index: 15;
}

.section-content {
  position: relative;
  z-index: 20;
}
