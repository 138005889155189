//
// Radio buttons control
//

.radio-buttons-group {
  max-width: 100%;
  overflow-x: auto;
  display: inline-flex;
  flex-wrap: nowrap;
  border: rem(1) solid $input-border-color;

  @if $enable-rounded {
    &:not(.border-0) {
      @include border-radius($border-radius);
    }
  }

  .btn {
    position: relative;
    border: 0;
    @include border-radius(0, true);

    // we need higher specificity here so I used [class]
    &, &:active[class], &:focus[class] {
      color: $text-muted;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: rem(2);
      background: $black;
      transition: $transition-fade;
      opacity: 0;
    }

    &.selected {
      &, &:active[class], &:focus[class] {
        color: $black;
      }

      &:before {
        opacity: 1;
      }
    }
  }
}
